// Simulates infinite scrolling on mobile for product categories and search results.

// Using jQuery to avoid JS bugs on mobile with click simulation.

// This function is conditioned based on the value of #is_loading in the DOM to avoid too many clicks during the request. See => generateNewBlocProd.

// The isInViewport function is used to check if the button is visible on the screen. If so, the click is simulated, otherwise not. This function is called in the click condition during scrolling.

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Fonction qui active ou désactive le scroll listener en fonction de si le premier clic est effectué et de l'avancée de la requête ajax. Voir => generateNewBlocProd.

function seeAllBtnClick(action=null) {
    if (action=="add") {
        window.addEventListener('scroll', handleScroll);
        window.hasScrollListener = true;
    } else if (action=="remove") {
        window.removeEventListener('scroll', handleScroll);
        window.hasScrollListener = false;
    } else {
        if (window.hasScrollListener) {
            window.removeEventListener('scroll', handleScroll);
            window.hasScrollListener = false;
        } else {
            window.addEventListener('scroll', handleScroll);
            window.hasScrollListener = true;
        }
    }
}

// Deuxième fonction qui écoute la position du scroll par rapport à la hauteur totale de la page. La condition demande que la fonction isInViewport renvoie true.

function handleScroll() {
    const maxItems = document.getElementById('totalElems').value;
    const productLoaded = $('#list_item .item:not(".push")').length;
    const pagerNav = document.querySelector('.pagerNav.end_pagi');
    const button = pagerNav?.querySelector('.button.primary');

    // Opacité modifiée et non display car isInViewport() a besoin de l'élément dans le viewport et pas seulement dans le dom pour fonctionner. La jauge de pagination n'apparaîtra que si tous les items ont été chargés dans la page.

    button.style.opacity = '1';
    // pagerNav.style.opacity = productLoaded < maxItems ? '0' : '1';

    if (isInViewport(button)) {
        button.click();
    }
    // We have loaded every product, so we remove the scroll listener and the cookie
    if (productLoaded >= maxItems) {
        seeAllBtnClick('remove');
        eraseCookie('infinite_scroll');
    }
}

// Fonction chargée de simuler le clic et de masquer le bouton au retour d'une fiche produit SI le cookie 'p_id' est présent ET si la valeur d'initial_page est supérieure à 1.

$(function() {
    /* if (getCookie('p_id') && $('#initial_page').val() > 1) {
        seeAllBtnClick();
    } */

    if ((getCookie('infinite_scroll') == "1") && ($('#initial_page').val() > 1)){
        if ($(".see_all_auto").length) $('.see_all_auto').fadeOut(300);
        seeAllBtnClick();
    } else if ((getCookie('infinite_scroll') == "1") && ($('#initial_page').val() == 1)) {
        eraseCookie('infinite_scroll');
    }
})
